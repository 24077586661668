import {
    CButton,
    CCol,
    CFormGroup,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow,
} from "@coreui/react";
import { Icon } from "components/common/iconselector/Icon";
import IconSelector from "components/common/iconselector/IconSelector";
import SearchLoader from "components/loader/SearchLoader";
import isValidUrl from "helper/UrlChecker";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IAddBookmarkModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    title: string,
    selectedCategoryId: string | null,
    selectedBookmark: IBookmark | null | undefined,
}

const AddBookmarkModal: React.FC<IAddBookmarkModal> = ({ refreshDetails, isOpen, onClose, title, selectedCategoryId, selectedBookmark }) => {
    const [link, setLink] = useState('');
    const [isLinkFromApi, setIsLinkFromApi] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [isLinkNameEmpty, setIsLinkNameEmpty] = useState(false);
    const [isResolveSpinning, setResolveSpinning] = useState(false);
    const [resolvedData, setResolvedData] = useState<IBookmark>();
    const [isInvalidUrl, setInvalidUrl] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [isSpinning, setSpinning] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<string | null>(Icon.folder);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();



    const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked)
    }

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
        setValue("Path", event.target.value)
        setValue("ShortUrl", event.target.value)
        setIsLinkFromApi(false);
    };

    const handleCheckTagBlur = () => {
        if (link === "") {
            setIsLinkNameEmpty(true);
        } else {
            setIsLinkNameEmpty(false);
        }
    }

    useEffect(() => {
        if (link === '') return;

        if (selectedBookmark && selectedBookmark.path === link) return;

        if (checked === false) return;
        if (!isValidUrl(link)) {
            setInvalidUrl(true);
            setIsLinkNameEmpty(false);
            return;
        } else {
            setInvalidUrl(false);
            setIsLinkNameEmpty(false);
        }
        if (timer) {
            clearTimeout(timer);
        }

        if (!isLinkFromApi) {
            const newTimer = setTimeout(async () => {
                if (link.trim() !== '') {
                    const data = {
                        Path: link,
                        CategoryID: selectedCategoryId
                    }

                    setResolveSpinning(true);
                    try {
                        const res = await api.post(Constants.GET_POST_RESOLVED_BOKMARK_DATA, data);
                        setResolvedData(res.data)
                    } catch (ex) {
                        setResolvedData(undefined);
                    } finally {
                        setResolveSpinning(false);
                    }
                } else {
                    setResolvedData(undefined);
                }
            }, 1500);

            setTimer(newTimer);
        }

        // Clean up the timer on unmount or input change
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [link, checked]);



    //Change the value when bookmark data are available
    useEffect(() => {
        if (resolvedData) {
            setValue("Icon", resolvedData.icon)
            setValue("Path", resolvedData.path)
            setValue("ShortUrl", resolvedData.shortUrl)
            setValue("Title", resolvedData.title)
            setValue("Description", resolvedData.description)
            setLink(resolvedData.path)
            setSelectedIcon(resolvedData.icon)
            setIsLinkFromApi(true);
        }
    }, [resolvedData])


    useEffect(() => {
        setValue("Type", 1)
    }, [])

    useEffect(() => {
        if (selectedIcon) {
            setValue("Icon", selectedIcon)
        }
    }, [selectedIcon])

    useEffect(() => {
        if (selectedCategoryId) {
            setValue("CategoryId", selectedCategoryId)
        }
    }, [selectedCategoryId])

    const onSubmit = async (data: any) => {

        setSpinning(true);
        try {
            const res = await api.post(Constants.POST_BOOKMARK_BY_CATEGORY_ID, data);
            toast.success("😊 Court Link added successfully.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            await onClose();
            refreshDetails();
        } catch (err: any) {
            // Handle errors
            if (err.status === 409) {
                toast.error("Duplicate Link Cannot be Added", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else {
                toast.error("Error found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }

        } finally {
            setSpinning(false);
        }
    }

    return (
        <div>
            <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
                <CModalHeader closeButton>
                    <CModalTitle>
                        {" "}
                        <div> {title}</div>{" "}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ fontWeight: "bold", fontSize: "12px" }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <label style={{ display: 'inline-block' }}>
                                <span style={{ display: 'block', color: '#4B5563', fontWeight: 'normal', marginBottom: '0.25rem' }}>
                                    <input
                                        type="checkbox"
                                        style={{ height: '1rem', width: '1rem', color: '#3B82F6', verticalAlign: 'middle', marginRight: '0.25rem' }}
                                        checked={checked}
                                        onChange={(e) => handleCheckedChange(e)}
                                    />
                                    Resolve URL
                                </span>
                            </label>
                        </div>


                        <div className="flex-two-row">
                            <IconSelector selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} iconOptions={Icon} />
                            <div className="form-title">
                                <label>Title*</label>
                                <input
                                    type="text"
                                    className="form-control "
                                    {...register("Title", { required: true, maxLength: 400 })}
                                    maxLength={400}
                                />
                                <small className="text-danger">
                                    {errors.Name?.type === "required" && "Bookmark Name is required"}
                                    {errors.Name?.type === "maxLength" && "Bookmark Name must not exceed 400 characters"}
                                </small>
                            </div>
                        </div>

                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Link*</label>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            type="url"
                                            className="form-control "
                                            value={link}
                                            {...register("Path", { required: true, maxLength: 1000 })}
                                            onChange={handleLinkChange}
                                            onBlur={handleCheckTagBlur}
                                            maxLength={1000}
                                        />
                                        {
                                            isResolveSpinning && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        border: 'none',
                                                        backdropFilter: 'blur(10px)',
                                                        height: '90%',
                                                        right: '0.5px',
                                                        top: '0.5px',
                                                        borderRadius: '0.375rem',
                                                        padding: '0.5rem 0.5rem',
                                                    }}
                                                >
                                                    <SearchLoader />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <small className="text-danger">
                                        {isLinkNameEmpty && "Link name is empty"}
                                        {isInvalidUrl && "Link is not valid"}
                                    </small>
                                </CFormGroup>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Description</label>
                                    <textarea
                                        placeholder="Optional"

                                        className="form-control"
                                        {...register("Description", { maxLength: 1000 })}
                                        maxLength={1000}
                                    ></textarea>
                                    <small className="text-danger">
                                        {errors.Description?.type === "maxLength" && "Bookmark Description must not exceed 400 characters"}

                                    </small>
                                </CFormGroup>
                            </CCol>
                        </CRow>

                        {!isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    style={{
                                        background: "var(--primary)",
                                        color: "white"
                                    }}
                                    type="submit"
                                    size="sm"
                                >
                                    Add
                                </CButton>

                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}

                        {isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    disabled
                                    style={{
                                        background: "var(--primary)",
                                        color: "white"
                                    }}
                                    size="sm"
                                >
                                    <i className="fa fa-spinner fa-spin"></i> Adding
                                </CButton>
                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}
                    </form>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default AddBookmarkModal;
